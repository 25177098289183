input.invalid__value,
textarea.invalid__value,
select.invalid__value {
  border-color: red !important;
  border:1px solid red !important
}

input.invalid__value::placeholder,
textarea.invalid__value::placeholder,
select.invalid__value::placeholder {
  color: red !important;
 
}

