
/**************************************** START MEDIA QUERY *********************************************/


@media only screen and (max-width: 600px) 
{

#phone-icon{
  width:13px;
}
.tabletnotmobile
{
    display:none;
}
#istabnotmobile
{
    display: none;
}
/********** START HEADER STYLE ******************/
header
{
 background: #ffffff;
}
/*** CANVAS MENU***/

#menu ul li a
{
 font-size: 15px;
}
#menu ul li
{
padding:1.5em 0.1em 0.1em;
margin:0;
}

 .strip
 {
   height: 60px;
 }
 header .strip
 {
margin:3px !important;
 }
 .phone-one
 {
 margin: 0;
    float: left;
 }
 .phone-two
{
    text-align: center;
    font-size: 15px;
    float: right;
    display: inline-block;
    margin-top: -2rem;
}

.sticky-top
{
  display:inline-block !important;
}
.navbar-collapse
{
  margin: 0px !important;
 
 
}
 .navbar-dark .navbar-nav a
 {
 text-align: left;
 margin:0px !important;
 border-bottom: 1px solid #bc946075;
 }

 .strip a
 {
   font-size: 12px;
   letter-spacing: 0;
 }
 .phone-one img
 {
   width:15px;
 }


 .custom-toggler.navbar-toggler 
 {
 width:100%;
 margin-top: 22px;
 }
 .custom-toggler .navbar-toggler-icon
 {
   margin-right: -80px;
 }

 .index-link a.image-logo-mobile
 {
    float: right;
    position: absolute;
    right: 36%;
    top: 0;
 }
 .index-link img
 {
  width: 100%;
     float: right;
     padding: 3px;
 }
 .index-link
 {
  background-color: transparent !important;
 }
 .navbar-nav
 {
   width:100% !important;
 }
 .commercial .index-link
 {
   background-color: transparent !important;
 }
 .commercial .navbar-dark .navbar-nav a#call-us {
  color: #312f2fd1 !important;
}
/************ START BANNER STYLE ************/

.carousel-item img
{
  height:180px !important;
}
.carousel-indicators
{
 top: 30%;
}
.carousel-control-prev, .carousel-control-next
{
   top: 4% !important;
   height: 178px !important;
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus
{
height: 280px;
margin-top: -20px;
}
.banner p
{
 padding-top: 37%;
 font-size: 25px;
}
.banner-404 h1
{
padding-top: 60%;
font-size: 37px;
letter-spacing: 5px;
}
.banner-404 label
{
   line-height: 24px;
   font-size: 15px;
   margin-top: 8%;
   white-space: break-spaces;
}

.banner label
{
  width:75%;
}
.btn-lg, .btn-group-lg > .btn
{
 margin-top: 10px;
 font-size: 15px !important;
 padding: .3rem 1rem !important;
}
.banner
{
 background-size: cover;
 height: 20vh;
}

.banner-about {
 background-repeat: no-repeat;
 width: 100%;
 background-position: 0px -7px;
 background-size: cover;
 height: 16vh;
}
.policy-banner
{
 background-position:0px;
}


.banner h1
{
 padding-top: 19%;
 font-size: 28px;
 letter-spacing: 1px;
 /* display: flex; */
 margin-top: 0rem !important;
 padding-top: 2rem !important;
}

 /*Banner form*/
 .bannerform 
 {
   margin-top: 0;
 }
.banner-projects h1
{
 font-size: 28px;
 letter-spacing: 3px;
 line-height: 28px;
 margin-bottom: 30px;
 padding-top: 2rem !important;
}

.banner-projects label
{
 line-height: 24px;
 font-size: 15px;
 margin-top: 0;
}

.bannerform .form-submit
{
 margin-top: 20px;
 padding-top: 4px;
 height: 40px !important;
 margin-bottom: 26px;
}
.bannerform .form-row
{
 border: none;
}
.banner-projects
{
 height:auto;
 background-size: cover;
 background-position: 0px 0;
}


.bannerform .form-row select
{
 background-position: 94% 19px !important;
}

/************ START FOOTER STYLE****************/
.eFirHq
{
  top:53% !important;
  padding-top:90px !important;
}
.bsEBnU 
{
  margin-left:70px !important;
}
.getsocial-mobile .fb-mobile img
{
 width:30px !important;
 height:32px !important;
}
/******** START FOOTER STRIP STYLE**********/
.footer-strip 
{
   margin-top:-60px;
}

.container-fluid1 img
{
 height: 180px !important;
}
.eFirHq h1 
{
 margin-top: -120px !important;
}

.footer-strip .phone-two
{
 text-align: center;
}
.strip
{
 text-align: center;
}

/******* POLICY *******/

.news-grid {
 padding: 0px 5% !important;
}


/********* TESTIMONIALS ************/

.carousel .control-next:after, .carousel.carousel-slider .control-next:after
{
 top: -8px;
}
.carousel .control-prev:after, .carousel.carousel-slider .control-prev::after
{
 top: -8px;
}
.carousel.carousel-slider .control-arrow
{
 margin-top: -34px;
 top: 40% !important;

}
.myCarousel p
{
 margin-bottom: 90px;
}
.testimonials .myCarousel p
{
 margin-bottom: 25px;
 max-height: none;
}
.myCarousel p
{
 margin-bottom: 25px;
 max-height: none;
}

.testimonials a
{
 padding:0;
}
/********* AWARDS **********/
.award-title
{
 height: 52px;
 text-align: center;
}
.award-content {
 margin-top: 30%;
}
.award {
 padding: 70px 0px;
}
.award-description
{
 padding:0px;
 margin-top: 25px;
}
/******* GOOGLE MAP *****/

.iframe-map
{
 padding:0px;
}

/****** CLIENTS LOGO *******/

.react-multi-carousel-item
{
 margin:0;
 padding:0;
 text-align: center;
 padding-top: 50px !important;
 margin-top: -10px;
}
.react-multi-carousel-item img
{
 width:fit-content !important;
}
.react-multiple-carousel__arrow
{
 min-width: 32px !important;
 min-height: 5px !important;
}
.clients-logo
{
padding: 8% 0;
}
.react-multiple-carousel__arrow
{
  top:55px;
}
.clients-logo img.keralanews
{
  width: 100% !important;
}


/*******ENQUIRE***********/
.enquiry
{
 padding-bottom: 30px;
}
.enquiry .iframe-map
{
 margin-left:0;
}
.enquiry .iframe-map img
{
 width:100%;
 height: auto;
 margin-top: 0 !important;
}
.enquiry .submit-row
{
 text-align: center !important;
}
.form-row select
{
 background-position: 95% 26px !important;
}
.enquiry .dropdown-select
{
   right: 67px !important;
   top: 7px !important;
   height: 59px;
}

.form-row input, .form-row textarea, .form-row select
{
padding: 0 8px !important;
 font-size: 11px !important;
 letter-spacing: 0px !important;
 height: 40px !important;
}
.form-row select
{
  background-image:none !important;
}
.bannerform .form-row {
  padding: 0 5px;
  margin: 0px;
}
.bannerform input[type="submit"] {
 margin-top: 0 !important;
 font-size: 9px !important;
}
.enquiry .submit-row
{
 margin-top: 0;
 position: initial;
}
.enquiry .submit-row .form-submit {
 letter-spacing: 3px;
 width: 100%;
 height: 41px;
 font-size: 13px;
}
.enquiry form
{
 margin-top: 0;
}
.enquire-form h1
{
 font-size:25px;
}
.enquiry .iframe-map img
{
  min-width: 100%;
}

.enquiry .form-row input, .enquiry .form-row select,.enquiry .form-row textarea
{
  height: 58px!important;
}
/*********CORPORATE OFFICE **************/

.corporate h3
{
 font-size: 30px;
 margin-top: 20px;
}

.corporate .text-center p,.corporate .text-center address
{
margin-top: -40px;
   margin-bottom: 60px;
} 

/********* ABOUT US **********/
.aboutus h1
{
 font-size: 28px;
 margin-top:0px;
 margin-bottom: 35px;
}

.second-about img,.first-about img
{
 height: auto;
}
.first-about p,.second-about p
{
 padding:0;
}

.second-about
{
 padding:0
}
.first-about img {
 width: 100%;
}

/***** FEATURES ********/

.features
{
 margin-top: -40px;
 margin-bottom:0px;
}
.feature-grid::after
{
 content: none !important;
}
.features
{
 height: auto;
}

.feature-grid
{
 margin: 0 2%;
}

.feature-grid label
{
 font-size: 13px;
}

/****** GALLERY ********/
.gallery .container-fluid
{

  background-size: cover;
}
.commercial .gallery .container-fluid
{

  background-size: cover;
}
.gallery
{
 padding-top: 0px;
}
.gallery h1
{
 font-size: 20px;
}

.gallery h3
{
 font-size: 15px;
 padding-top: 5%;
}
.gallery .filter-nav button
{
 margin:5px;
 width: 75%;
 height:45px
}
.filter-nav
{
 margin-top: 35px;
 padding: 15px;
}

.galbox .img-fluid
{
 padding: 0 10px;
}
.slick-slider .slick-prev
{
  width: 35px;
  position: absolute;
  top: 10rem;
  z-index: 3;
  left: 0;
}
.slick-slider .slick-next
{
  width: 35px;
  position: absolute;
  right:0;
  top: 10rem;
}
.slick-list
{
  clear:both;
}

.gallery .slick-slide img
{
  height:400px;
  width: 100%;
}
/** new start mobile gallery**/

.gallery .myCarousel
{
  width:100%;
}
.gallery .carousel .slide img
{
    width: 100% !important;
    border-radius: unset;
    margin-top: 0px;
}
.gallery .control-dots
{
  display:none;
}
.gallery .carousel .control-next.control-arrow {
  right: -76px !important;
}
.gallery .carousel .control-prev.control-arrow {
  left: -73px !important;
}
.gallery .carousel.carousel-slider .control-arrow {
  top: 23% !important;
}
.gallery .carousel-root
{
  width:100%;
  padding:0;
  margin:0;
}
.gallery .slick-slider .slick-next,.gallery .slick-slider .slick-prev
{
  width:25px;
}
.gallery .react-multiple-carousel__arrow
{
  top:12rem;
}
.gallery .react-multiple-carousel__arrow--left
{
  left: calc(1% + 1px);
}
.gallery .react-multiple-carousel__arrow--right
{
  right: calc(2% + 1px);
}
/******** RESIDENTIAL AMENITIES************/

.residential .amenities
{
 height:auto;
 background-position: 0px 0px;
 margin-top: 0px;
}
.commercial .amenities
{
 height: auto;
 background-position: -15px 0px;
 padding-bottom: 40px;
 margin-top: 0px;
}

.residential .amenities h1
{
 padding-top: 0px;
 margin-bottom: -25px;
}
.hexagon
{
 margin: 1rem auto;
}
.residential .amenities h2
{
 font-size: 15px;
 color: #dedddd;
 margin-bottom: 3rem;
 margin-top: 4rem;
}

.residential .amenities .carousel .slide img
{
  max-width: 55px;
  min-width: 55px;
  width: fit-content !important;
}

.testimonials .myCarousel
{
 height: auto;
}

.hexagon:before {
 content: "";
 width: 0;
 height: 0;
 position: absolute;
 top: -42px;
 left: 0;

}
.hexagon:after {
 content: "";
 width: 0;
 height: 0;
 position: absolute;
 bottom: -42px;
 left: 0;

}

.hexagon {
  width: 140px;
  height: 70px;
}

.hexagon:before
{
  border-left: 71px solid transparent;
  border-right: 69px solid transparent;
  border-bottom: 42px solid #bc9461;
}
.hexagon:after
{
  border-left: 71px solid transparent;
  border-right: 69px solid transparent;
  border-top: 42px solid #bc9461;
}

.residential .amenities .carousel .slide img {
  margin-top: 0;
}

.residential .carousel .control-dots .dot
{
 background: #bc9461 !important;
}

.residential .carousel.carousel-slider .control-arrow
{
 top: 43% !important;
}
.residential .carousel .control-next.control-arrow
{
 right: -4px;
}

.residential .carousel .slider-wrapper
{
    padding-top: 2rem;
}
.residential .hexagon h2
{
    margin-top: 5rem;
    margin-bottom: 2rem;
}


.residential .amenities .control-dots
{
  display:none;
}

/* .amenities .slider li .row .col-6:nth-of-type(odd) .myCarousel:nth-of-type(odd){
height: 35vh;
}
.amenities .slider li .row .col-6:nth-of-type(even) .myCarousel:nth-of-type(odd){
  height: 35vh;
  } */

/****** POLICY ******/

.panel-title label:before, .panel-title label:after
{
 content: unset;
}

/******* PROJECT PAGE FORM********/
.project-page .form-row
{
 border-bottom: none;
}
.project-page .dropdown-select
{
 z-index: 3;
 right:-80% !important;
 top: -1px !important;
 height: 56px;
}
.project-page .form-row select {
 background-position: 93% 26px !important;
}

.residential .enquiry,.commercial .enquiry
{
margin-top: -1px;
}

.project-page .form-row input,.project-page .form-row textarea,.project-page .form-row select
{
font-size:15px !important;
height: 58px !important;
padding: 4px 16px !important;
}


/****************** PROJECT DETAILS ************************/

.project-info h1
{
 font-size: 31px;
 margin-bottom: 18px;
}
.project-info h2
{
 color: #bc9461;
 margin-top: 18px;
 margin-bottom: 4px;
 font-size: 15px;
}
.project-info h3
{
 font-size: 15px;
 padding: 0;
 margin-top: 15px;
}
.project-info p
{
 padding:0
}

.project-content img
{
width:100%;
padding-top: 0px;
}
.project-button
{
 padding: 10px;
}
.project-button-one
{
 width:100%;
 margin:0 0px 5px 0;
}
.project-button-two
{
 width:100%;
 text-align: center;
}

.react-tabs__tab
{
 padding:18px 6px !important;
}

.react-tabs__tab
{
 width:49%;
 font-size: 11px
}
.react-tabs__tab-list
{
 padding: 5px;
}
.project-details .project-content
{
 padding: 1%;
}
.project-details
{
padding: 1%;
}
.project-details .list-group-item:first-child
{
 font-size: 13px;
}
.project-details .list-group-item
{
 font-size: 14px;
}
.project-button-two
{
 background-position: 97% 5px !important;
}
.list-group
{
  margin-top: 1rem;
}

/*commercial project page*/

.commercial .react-tabs__tab
{
 padding: 18px 11px !important;
 height: 52px;
 margin: 0;
 width: 50%;
 font-size: 11px;
 line-height: 14px;
}
.commercial .project-info p
{
 width:100%;
}

/**************** LANDMARKS ********************/
.landmark
{
 height: auto;
 background-size: cover;
 min-height: inherit;
}
.landmark-head
{
 padding-right: 15px;
 padding-left: 15px;
}
.landmark .landmark-h1 h1
{
 font-size: 24px;
}
.landmark .accordion
{
 padding: 0 15px;
}
.landmark .accordion > .card,.landmark .accordion > .card:first-child
{
 padding: 0;
}
.landmark .accordion > .card > .card-header
{
 padding: 15px 35px;
}
.landmark .card-header label:before, .landmark .card-header label:after
{
 margin-top: 0px !important;
}
.landmark-img img
{
 padding: 0 15px;
}
.landmark .card-header label
{
 margin-top: 11px;
}
.landmark .card-header button
{
 font-size: 14px;
}
.landmark .card-body ul li
{
 font-size: 13px;
}

/*************** Blog start ********************/
.blog-details .galbox .href
{
  margin-bottom: 30px;
}
.blog-details .react-tabs__tab-list
{
  display: inline-flex;
  overflow: scroll;
  max-width: 100%;
}
.blog-details .react-tabs__tab {
  display:inline-block;
}

.blog-comments
{
  padding:0;
}
.blog-comments-inner,.blog-comments-replay,.blog-comments-form
{
  margin:0;
}

.blog-comments-form .project-button-one
{
  margin-bottom:15px;
}

.rc{
  margin: 3% 13%;
}
.view
{
  height: auto;
}


.blog-details .galbox .item-content
{
  height: 405px;
}

}

/**************************************** END MEDIA QUERY *********************************************/


/****LIVE ISSUE*******/


.eFirHq {
   padding: 80px 60px;
   background: black;
   position: absolute;
   bottom: 0;
   width: 100%;
}
.eFirHq {
   padding-left: 0px !important;
   padding-right: 0px !important;
   padding-top: 15px;
   padding-bottom: 45px !important;
   position: inherit !important;
}
.eFirHq {
   background: #e5d6c3 !important;
}
.fwPDcD {
   display: -webkit-box;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   -webkit-flex-direction: column;
   -ms-flex-direction: column;
   flex-direction: column;
   -webkit-box-pack: center;
   -webkit-justify-content: center;
   -ms-flex-pack: center;
   justify-content: center;
   max-width: 1000px;
   margin: 0 auto;
}
.ivXNbA {
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(185px,1fr));
   grid-gap: 0px;
}
.bsEBnU {
   display: -webkit-box;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   -webkit-flex-direction: column;
   -ms-flex-direction: column;
   flex-direction: column;
   text-align: left;
   margin-left:5px;
}
.eEKhcr {
   color: #fff;
   margin-bottom: 30px;
   font-size: 15px;
   -webkit-text-decoration: none;
   text-decoration: none;
   /* font-family:'Gotham'; */
}
.eFirHq a {
   color:#343331c9 !important;
}
.eFirHq a  {
 margin-top:10px;
}
.eFirHq p {
   color: #343331 !important;
   font-size: 15px !important;
   margin-top: 10px;
}
.kMHvnv {
   font-size: 24px;
   color: #fff;
   margin-bottom: 40px;
   font-weight: bold;
}
.kMHvnv {
   margin-bottom: 23px !important;
}
bsEBnU:last-child {
   display: inline-block !important;
}

/***** 5 aug ****/

.index-link-mobile .navbar-toggler-icon
{
 background: 24%/100% 100% no-repeat !important;
}
@media only screen and (max-width: 600px) 
{
.canvas-menu-background
{
 background: 50% / 30% 100% no-repeat;
}
}



@media only screen and (min-width: 600px) 
{
 .canvas-menu-background
 {
 background: 50% / 18% 100% no-repeat;
 margin-right: 10rem;
 }
}
/************************************* MOBILE VIEW ********************************************************/

@media only screen and (max-width: 600px) 
{

/*HEADER STYLE*/

 .strip-mobile .logo a img
 {
   width:100px;
   
 }

 .phone-one-mobile,.logo,.phone-two-mobile
 {
   width:33.333%;
 }

 .phone-one-mobile {
   float: left;
   width: 33.333%;
   padding-top: 18px;
 }

 .logo {
   float: left;
   width: 33.333%;
   padding: 5px;
 }

 .phone-two-mobile {
   display: inline-block;
   width: 33.333%;
   padding-top: 18px;
 }

 a.stripphone-mobile,.sec-num-mobile
 {
   font-size:15px;
   color:#545050;
 }
 .phone-one-mobile img
 {
   width:15px;
 }

 nav.bg-dark
 {
 width:100%;
 height: auto;
 background: none !important;
 float:right;
 }

 .navbar-dark .navbar-toggler-icon
   {
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28000, 000, 000, 0.9%29' stroke-linecap='round' stroke-miterlimit='30' stroke-width='4' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
   }

 .index-link-mobile 
 {
   margin-top: -40px;
 }
 .commercial nav.bg-dark
 {
   background: none !important;
   float: right;
 }

 .commercial .custom-toggler .navbar-toggler-icon
 {
   background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28000, 000, 000, 0.5%29' stroke-linecap='round' stroke-miterlimit='30' stroke-width='4' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
 }
/****** FOOTER STYLE*******/

.accordion
{
 padding: 25px;
 margin-top:0px;
}
.card-body
{
 padding:5px !important;
}
.card-body a
{
display: flex;
padding: 5px;
color: #000;
}

.card-header button:focus,.card-header button
{
 outline: none !important;
   box-shadow: none !important;
}
.card-header button,.card-header button:hover,.card-header button:active,.card-header button:visited,.card-header button:focus
{
 text-decoration: none;
}
.card-header label
{
 position:relative;
}
.btn-label-accordian
{
 float: right;
}
.card-header label:before, .card-header label:after {
 border-right: 1px solid;
 content: '';
 display: block;
 height: 8px;
 margin-top: 5px;
 position: absolute;
 transform: rotate(
212deg
);
 right: 10px;
 top: 15%;
 width: 0;
 margin-right: 3px;
}


.card-header label:after {
 margin-top: 5px;
 transform: rotate(
145deg
);
 margin-right: 8px;
}
.btn-link,.btn-link:hover
{
 color:#000000 !important;
 margin: 5px -15px;
 padding: 0px !important;
}
.card-header
{
 background-color: transparent !important;
}

.accordion > .card > .card-header, .accordion > .card
{
 border: none !important;
}
.accordion > .card 
{
 border-bottom: 1px solid #0000002b !important;
}


.getsocial-mobile {
 display:block;
 flex-direction: column;
 text-align: center;

}

.getsocial-mobile:last-child {
 display: inline-block !important;
}

.getsocial-mobile img {
 width: 40px !important;
 height: 30px !important;
 padding: 5px 10px;
 margin: 0px;
 margin-left: -8px;
 margin-right: 4px;
}


.social-a {
 color: #fff;
 margin-bottom: 30px;
 font-size: 15px;
 text-decoration: none;

}
.fb-cls img
{
width: 30px !important;
height: 28px !important;
}

.social-p {
 font-size: 12px;
 color: #000000;
 margin-bottom: 40px;
 text-align: center;
 margin-bottom: 5px !important;
 text-transform: uppercase;
}


/****** FOOTER STRIP ***********/


.footer-strip-mobile{
 margin: 0px !important;
 height: 40px;
 padding: 10px;
 height: 60px;
 text-align: center;
}

.footertag-mobile {
 display: grid;
}
.footer-strip-mobile a {
 color: #484643 !important;
 text-decoration: none !important;
 letter-spacing: 2px;
 font-size: 13px;
}
.footertagtwo-mobile {
 text-align: right;
}


/*********** AMENITIES MOBILE **************/

.amenities 
{
height: 910px;
padding-top: 15px;
background-position:31px ​-512px;
}
.amenities .carousel-root
{
  width:100% !important;
  padding: 0 5% !important;
}
.amenities h1
{
 font-size:31px;
 padding-bottom: 15px;
}
.amenities .carousel .slide
{
 background: transparent !important;
}
.amenities .carousel .slide img
{
 width:100% !important;
 border-radius: unset;
 max-width: 55px;
    min-width: 55px;
}

.square-service-block a
{
 padding: 0;
 height: 180px !important;
}
h2.ssb-title
{
margin-top:0px;
font-size: 13px !important;
color: #000000 !important;
}
.amenities .carousel .slide img
{
 margin-top: 40px;
}
.square-service-block
{
 margin:0px;
}

.amenities .myCarousel
{
 width: 100%;
 padding: 0;
 margin: 0;
 height: 35vh !important;
}

.carousel .control-prev.control-arrow
{
 left:-3px !important;
}
.carousel .control-next.control-arrow {
 right: -7px;
}

.amenities .control-dots
{
  display: none;
}

}
.clients-logo .react-multiple-carousel__arrow
{
  display: none;
}

/******* TABLET *********/

@media (min-width: 768px) and (max-width: 1024px) 
{

      .tablet,.ismobilenottab
      {
          display:none;
      }
      #istabnotmobile
      {
          display: block !important;
      }
      .navbar-nav
      {
        width:100% !important;
      }

      /* header
      {
        background-color: #ffffff !important;
      } */
      .tablet,.ismobilenottab
      {
      display:none;
      }
      #istabnotmobile
      {
      display: block !important;
      }
      nav
      {
        width:100%;
      }
      .navbar-dark .navbar-nav a#call-us {
        background-color: #ffffff;
    }
    .commercial .navbar-dark .navbar-nav a#call-us {
    color: #000000a8 !important
    }
    .galbox .img-fluid
    {
      height:auto !important;
    }
    .ivXNbA .bsEBnU img[src="https://sddevelopers.co.in/static/images/fb.png"]
    {
      width: 31px;
    }
    .bsEBnU
    {
      margin-left: auto !important;
    }
    .enquiry .submit-row .form-submit
    {
      width:100% !important;
    }
    .banner h1
    {
      padding-top: 5rem;
    }
    .first-about img {
      width: 100%;
  }

  .residential .gallery, .commercial .gallery
  {
    background-size: cover;
  }
  .navbar-dark .navbar-nav a
  {
    letter-spacing: 1px;
    display: block !important;
  }
  .commercial .react-tabs__tab
  {
    margin:0;
  }
  .banner-projects
  {
    background-size: cover;
    background-position: 0px 0px;
  }
  .bannerform .form-row select
  {
  background-position: 98% 34px !important;
  }
.project-details .project-button-two
  {
    font-size: 12px;
    background-image: none !important;
    padding-left:3%;
  }
  .project-button-one
  {
    font-size: 12px;
  }

  
  header .index-link,header .bg-dark
  {
    /* background-color:transparent !important; */
    height: 105px;
  }
  .commercial .bg-dark ,.commercial .index-link
  {
    /* background-color:transparent !important; */
  }
  .banner,.banner-about
  {
    height:45vh !important;
  }
  .policy-banner
  {
    background-position: 0px 0px;
  }
  .banner-about
  {
    background-position: -442px 21px;
  }

  .navbar-nav
  {
    margin-left: -200px;
  }
  .view
  {
  height: auto;
  }

  .blog-comments-form .form-submit
  {
  padding: 10px !important;
  }

  .view img{
    height:250px;
  }
  .policy-banner h1
  {
    padding-top: 10% !important;
  }
  .first-about p,.second-about p
  {
    padding:0;
  }
  .bannerform .form-submit
  {
    font-size: 13px;
  }
  /* .navbar-collapse
  {
    margin-top: 6rem !important;
   
  } */
  .blog-details img
  {
  height: 200px;
  }

img.blognoimg
{
  width:40px;
}
.index-link img
{
  max-width: 105px;
}

.navbar-dark .navbar-toggler
{
  margin-top: 25px;
}
.news-grid iframe
{
  height:237px;
}

}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {

  .navbar-nav
  {
    margin-left: 0px !important;
  }
  .banner h1 {
    padding-top: 10rem !important;
}

.navbar-collapse
{
  margin-left: 0 !important;
}
  
.navbar-dark .navbar-nav a
{
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.blog-comments
{
  margin-top: 10%;
}

}

@media only screen and (max-width: 992px) 
{
  .gallery .slick-slide img {
    height: 400px;
  }
}


@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {

div.navbar-collapse
  {
    margin-top: 1rem !important;
  }

  .banner-about {
    background-position: -110px 16px;
}
.banner, .banner-about {
  height: 45vh !important;
}

nav.sticky-top
{
  margin-left: 40px !important;
}

}





@media only screen and (min-width: 1024px) and (max-height: 1366px)
    and (-webkit-min-device-pixel-ratio: 1.5) and (hover: none) {
  

      .banner-about {
        background-position: -593px 21px;
    }


}




@media only screen and (min-width: 1024px) and (max-height: 1366px)
    and (-webkit-min-device-pixel-ratio: 1.5) and (hover: none) and (orientation : landscape) {
  

      .banner-about {
        background-position: top;
    }

    nav.sticky-top
    {
      margin-left: -190px;
    }

    .navbar-nav .d-lg-none
    {
      display: block !important;
    }
    .navbar-dark .navbar-nav a#call-us
    {
      background-color: #ffffff !important;
    }

    .gallery .filter-basic
    {
      margin-top: 0 !important;
    }
   

}



@media only screen and (min-width: 1024px) and (max-height: 1366px)
    and (-webkit-min-device-pixel-ratio: 1.5) and (hover: none) and (orientation : portrait) {
  
      .banner-about {
        background-position: top;
    }

    .navbar-dark .navbar-nav a
    {
      margin-right: 15px !important;
    }


    .news-grid iframe {
      height: 242px;
  }


}



@media screen and (orientation:landscape) and (-webkit-min-device-pixel-ratio: 3){

.navbar-dark .navbar-nav a
{
    background-color: #ffffff !important;
}

.navbar-nav
{
  height: 200px !important;
  overflow-y: scroll;
}

.gallery .slick-prev,.gallery .slick-next{
  width:50px;
}

.gallery .slick-slider
{
  text-align: center;
}

.amenities .myCarousel h2
{
  font-size: 12px;
  color: #ffffff;
}
.carousel .slide .hexagon img
{
  width:38%;
  border-radius:inherit;
  margin-top: 20px;
}


 }