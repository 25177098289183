/***** slick******/
/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}
/******** COMMON STYLE **********/

@font-face {
  font-family: 'Gotham';
  src:url(fonts/Gotham.ttf);
}

@font-face {
  font-family: 'GillSans';
  src:url(fonts/GillSans.ttf);
}

@font-face {
  font-family: 'BigCaslon';
  src:url(fonts/BigCaslon.ttf);
}

@font-face {
  font-family: 'AGaramondPro';
  src:url(fonts/AGaramondPro-Regular.otf);
}


a,a:hover{text-decoration: none !important;}
body
{
  font-family: 'Gotham';
}
::-moz-selection {
  background: #bc9461;
}
::selection {
  background: #bc9461;
}
.pr-6, .px-6 {
  padding-right: 4rem !important;
}
.pl-6, .px- {
  padding-left: 4rem !important;
}
#istabnotmobile
{
  display: none;
}
/********** START HEADER STYLE ******************/
header
{
    display: block;
    position: fixed;
    width: 100%;
    z-index: 9;
    top: 0;
}
/**CANVAS MENU**/

#offcanvas-root div div
{
  height:100vh !important;
  background: rgb(0 0 0 / 79%) !important;
}

#offcanvas-root.active #menu ul li
{
  opacity: 1;
  -ms-transform: translateX(0);
  transform: translateX(0);
}

#offcanvas-root.active{
  transition: all 500ms;
}


#offcanvas-root.active #menu ul li:nth-child(1) {
  transition: all 0.2s .06s;
}

#offcanvas-root.active #menu ul li:nth-child(2) {
  transition: all 0.3s .16s;
}

#offcanvas-root.active #menu ul li:nth-child(3) {
  transition: all 0.4s .26s;
}

#offcanvas-root.active #menu ul li:nth-child(4) {
  transition: all 0.5s .36s;
}

#offcanvas-root.active #menu ul li:nth-child(5) {
transition: all 0.6s .46s;
}
#offcanvas-root.active #menu ul li
{
  opacity: 0;
  -ms-transform: translateX(-20px);
  transform: translateX(-20px);
}

#offcanvas-root.active #menu ul li
{
  opacity: 1;
  -ms-transform: translateX(0);
  transform: translateX(0);
}

#menu ul li
{
  list-style-type: none;
  padding: 0.5em 0.1em 0.1em;
  margin: 40px 10px;
  border-bottom: 1px solid #5f5f63;
}
#menu ul li a
{
  color: #bc9461;
  text-transform: uppercase;
  font-size: 20px;
  
}
.canvas-close
{
  font-size: 23px;
  color: #ffffff;
  cursor: pointer;
  padding:10px;
}

#menu ul li a::before
{

display: inline-block;
margin-right: .5rem;
}

#menu ul li a:hover
{
  color: #ffffff;
  cursor: pointer;
}
#menu ul li a.active
{
  color:#ffffff;
}
.navbar-dark .navbar-nav a#call-us
{
  background-color: transparent;
}
.navbar-dark .navbar-nav a:hover
{
background-color: #bc9461 !important;
}
.commercial .navbar-dark .navbar-nav a#call-us
{
  color:#ffffff !important;
}
/** CANVAS MENU END***/


  nav.bg-dark
  { 
     /* height: 100px; */
     width: 100%;
  }

  .navbar-dark .navbar-nav a
  {
    padding: 15px;
    background-color: #ffffff;
    color: #312f2fd1 !important;
    font-size: 12px;
    font-family: Sans-serif;
    letter-spacing: 5px;
    width: 100%;
    text-align: center;
    text-decoration: none;
  }
  .navbar-dark .navbar-nav .active
  {
    background-color: #bc9461;  
  }
  .index-link
  {
  display: flex;
  background-color: #d9d3c8 !important;
  padding:0px;
  }
  .bg-dark
  {
    background-color: #d9d3c8 !important;
  }
  .commercial .index-link
  {
    background-color: #282828 !important;
  }
  .commercial .bg-dark
  {
    background-color: #282828 !important;
  }
 .index-link img
 {
   max-width: 107px;
 }
 .sticky-top
 {
   z-index: 1;
 }
 .strip
 {
   margin:0px !important;
   padding: 10px;
  
 }
 header .strip
 {
  background: #ffffff;
 }
 .strip a
 {
   color:#484643 !important;
   text-decoration: none !important;
   letter-spacing :2px;
   font-size: 13px;
 }
 .phone-one
 {
  text-align: center;
 }
 .phone-two
 {
  text-align: center;
 }
 .navbar-nav
 {
   width:100%;
 }




 .custom-toggler.navbar-toggler {
  width: 100%;
  border: none !important;
}
.custom-toggler .navbar-toggler-icon {
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28000, 000, 000, 0.6%29' stroke-linecap='round' stroke-miterlimit='30' stroke-width='4' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
  width: 100%;
  display: inline-block;
  background-repeat: no-repeat;
}

.commercial .custom-toggler .navbar-toggler-icon
{
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.9%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
  
}

@media (min-width: 992px)
{
.custom-toggler{
    display: inline !important;
}
}
 /************ START BANNER STYLE****************/

.banner
{
  /* background-repeat: no-repeat; */
  height: 53vh;
  background-size: cover;
  width: 100%;
}
.banner-404{
  height: 100vh;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: cover;
 }
 .carousel-item img
 {
  height: 500px !important;
 }
 .carousel .carousel-inner .item, .carousel .carousel-inner .carousel-item
 {
  height: 500px !important;
 }

 .carousel-indicators
 {
   top:78%;
 }
 .carousel-control-prev, .carousel-control-next
 {
 height:435px !important;
 }

.banner h1
{
    text-align: center;
    font-size:50px;
    color: #ffffff;
    padding-top:5%;
    letter-spacing: 4px;
    line-height: 1px;
    margin-top:10% !important;
    font-family: 'AGaramondPro';
}
.banner label
{
  font-size: 16px;
    color: #e6e0d9;
    text-align: center;
    display: grid;
    padding-top: 20px;
    font-family: sans-serif;
    width: 18%;
    margin-top: 25px;
  
}
.banner-404 h1
{
font-size:67px;
padding-top: 17% !important;
text-transform: uppercase;
text-align: center;
font-size: 50px;
color: #ffffff;
padding-top: 20%;
letter-spacing: 8px;
line-height: 1px;


}
.banner-404 label
{
  color: #fbf9f9;
    text-transform: uppercase;
    line-height: 32px;
    font-size: 21px;
    margin-top: 2%;
    white-space: break-spaces;
}

.banner-404 .btn-primary
{
  display: block;
}

.btn-primary
{
  background-color: #bc9461 !important;
  border-color: #bc9461 !important;
  color: #302a23 !important;
  padding: 0.6rem ​2rem !important;
  border-radius: 0 !important;
  margin-top: 30px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 14px;
  height: 47px;
}

.banner-about
{
  background-repeat: no-repeat;
  height: 54vh;
  width: 100%;
  background-position: 0px 0px;
  background-size: cover;
}
.policy-banner
{
  height: 41vh;
  background-position: 0px 136px;
}


/*Projects banner*/
.banner-projects
{
  background-size: cover;
  /* background-position: 0px -192px; */
  margin-bottom: -4rem;
}
.banner-projects h1
{
  text-align: center;
  font-size: 50px;
  color: #ffffff;
  letter-spacing: 5px;
  line-height: 58px;
  font-family: 'AGaramondPro';
  white-space: pre-wrap;
  padding-top: 10rem !important;
}
.banner-projects label
{
  color: #fbf9f9;
  text-transform: uppercase;
  line-height: 32px;
  font-size: 21px;
  margin-top: 2%;
  white-space: break-spaces;
  font-family: 'GillSans';
}

/*BANNER FORM*/

.bannerform .form-row select
{

  background: #0000008a;
  color: #dad7d4;
  border: 2px solid #997c59;
  text-transform: uppercase;

  font-size: 13px;
  letter-spacing: 1px;
  background-position: 95% 23px !important;
}


.bannerform .form-submit
{
  border-radius: unset;
  background:#bc9461;
  color:#33342e;
  letter-spacing: 2px;
  font-size: 15px;
  height: 50px !important;
  padding:10px;
}


.bannerform input[type="submit"]
{
  border: none;
}


.banner-projects .form-row select option
{
background: #ffffff;
}
.policy-banner h1{
  padding-top: 3% !important;
}
 /******** START FOOTER STYLE*************/

 .eFirHq
 {
   background:#e5d6c3 !important;
 }
.eFirHq a
{
color: #343331 !important;
}

.eFirHq p
{
  color: #343331 !important;
  font-size:15px !important;
  margin-top: 10px;
}
.bsEBnU img 
{
  width: 40px;
  height: 31px;
  padding: 5px 10px;
  margin: 0px;
  margin-left: -8px;
    margin-right: 4px;
}
.bsEBnU:last-child
{
  display:inline-block !important;
  width: 100%;
}

.eFirHq
{
  padding-left:0px !important;
  padding-right:0px !important;
  padding-top:15px;
  padding-bottom:45px !important;
  position:inherit !important;
}
.kMHvnv
{
  margin-bottom: 23px !important;
}
hr
{
  color:#33342e !important;
  border-top:1px solid rgb(0 0 0 / 43%) !important
}
.getsocial-mobile a img
{
  width:10%;
}
.ivXNbA
{
  grid-gap: 20px;
}
.bsEBnU
{
  margin-left:0;
}
/********** START FOOTER STRIP STYLE ******************/
.footer-strip
{
margin-top:-50px;
}

/* .footer-strip .strip
{
  text-align: center;
} */
.footertag
{
  text-align: center;
}
.footertagtwo
{
  text-align: right;
}
.footertag a
{
  font-size: 12px;
}
/************** START POLICY PAGE *****************/
.accordian_wrapper
{
  background: #f3ece6;
  padding:65px 0px;
}
.panel-title 
{
  cursor: pointer;
}
.panel-title a.active
{
  color:#bc9462;
  margin-left: 20px;
}
.panel-title a.active ~ div font
{
color:#bc9462;
}
.panel-title a
{
  font-size: 13px;
  color:#000;
  text-decoration:none !important;
  letter-spacing: 3px;
}
#accordion {
    margin-top:25px;
}
.well {
    margin-top: 25px;
    padding: 0 20px;
}
.panel-title font
{
  float: right;
  font-size: 15px;
  margin-top: -20px;
  margin-right: 15p
   
}
.panel-title a:hover
{
color:#bc9462 !important;
}
.panel-title hr
{
  border-top: 1px solid #38353963!important;
}
.well p
{
    color: #0d1014ad;
    font-size: 17px;
    font-family: sans-serif;
    line-height: 34px;
    font-family: 'AGaramondPro';
}
.well p li
{
  color: #0d1014ad;
  font-size: 17px;
  font-family: sans-serif;
  line-height: 34px;
  font-family: 'AGaramondPro';
}
.well p strong
{
  color: #0d1014ad;
  font-size: 17px;
  font-family: sans-serif;
  line-height: 34px;
  font-family: 'AGaramondPro';
}
.panel-title label {
  float:right;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  padding: 10px 15px 10px 10px;
  position: relative;
  text-decoration: none;
  
}


.panel-title label:before, .panel-title label:after {
  border-right: 1px solid;
  content: '';
  display: block;
  height: 8px;
  margin-top: -6px;
  position: absolute;
  -moz-transform: rotate(146deg);
  -o-transform: rotate(146deg);
  -webkit-transform: rotate(146deg);  
  transform: rotate(146deg);
  right: 10px;
  top: 102%;
  width: 0;
}

.panel-title label:after {
  margin-top: -1px;
  -moz-transform: rotate(33deg);
  -o-transform: rotate(33deg);
  -webkit-transform: rotate(33deg);
  transform: rotate(33deg);
}

/********* START COMMERCIAL PAGE *************/
.news-grid
{
  padding:0px !important;
}
.section-preview {
         
  padding:100px 0px !important;
        }
    .view {
    position: relative;
    overflow: hidden;
    cursor: default;
    padding: 20px;
    border: 1px solid #d9d3c8;
    margin: 5px;
    height:390px;
}
.view img, .view video {
    position: relative;
    display: block;
}
.img-fluid, .modal-dialog.cascading-modal.modal-avatar .modal-header, .video-fluid {
    max-width: 100%;
    height: auto;
}
.view .mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-attachment: fixed;
}
.waves-effect {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}
/* .mask {
    background: url(https://mdbcdn.b-cdn.net/wp-content/themes/mdbootstrap4/img/overlays/01.png);
    background-attachment: fixed;

} */
.flex-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
}
.flex-center h3 {
    margin: 0;
    color:#ffffff;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 2px;
}
.flex-center h3 font 
{
  font-size: 20px;
}
.white-text hr
{
  border-top: 1px solid white !important;
  width: 37% !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.view img
{
height:300px;
width:100%;
}
.white-text span
{
  font-size: 14px;
  border-bottom: 1px solid #bbb5b5;
  padding-bottom: 7px;
  display: inline-block;
}



/********* AWARDS **********/
.award-heading
{
  text-align: center;
}
.award-heading h4
{
font-size: 40px;
letter-spacing: 3px;
text-transform: capitalize;
font-family: 'AGaramondPro';
}
.award
{
  background: #e4dfdc;
  padding: 40px 0px;
}
.award-title
{
  text-align: center;
  margin-top: 40px;
}
.award-title font
{
  font-weight: 100;
  color: #d2d2d2;
}
.award-description
{
  font-size:17px;
  color: #2a2c2f;
  margin-bottom: 0px;
  text-align: center;
  padding: 0 30%;
  font-family: 'AGaramondPro';
}

.award-content
{
  margin-top: 30px;
}
h3.award-p {
  text-transform: uppercase;
  color: #282828;
  font-size: 20px;
  text-align: center;
}

/*Residential Award*/
.residential .award
{
  background:#ffffff;
}
.residential h3.award-p
{
  color: #bc9461;
}

/************* TESTIMONIALS ******************/

.testimonials
{
  background: #282828;
}

.testimonial-heading
{
  text-align: center;
  padding-top: 60px;
}
.testimonials a 
{
  cursor: pointer;
  padding: 10px;
}
.testimonials a img
{
  width: 40px;
  height: 40px;
}
.testimonial-right-arrow
{
  position: absolute;
  right: 0px;
  top: 0px;
}

.carousel-root {
  width: 100% !important;
  margin: auto !important;
  padding:0 10%;
}
.carousel .slide {
  color: black;
  height: 100%;
}

.carousel .slide img {
  width: 100px !important;
  border-radius: 50%;
}

.myCarousel {
  margin-top: -6%;
  width: 54%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  height: 286px;
}

.carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 5% !important;
}

.myCarousel h3 {
  color: #222;
  font-weight: 100;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}

.myCarousel h4 {
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  color: #787878;
  font-size: 14px;
}

.myCarousel p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: #ffffff;
  font-size: 16px;
  font-family: 'AGaramondPro';
  max-height: 67px;
  margin-bottom: 50px;
}

.myCarousel p:before {
  content: "\201C";
  color: #aaa;
  font-size: 26px;
  font-weight: 100;

}

.myCarousel p:after {
  content: "\201D";
  color: #aaa;
  font-size: 26px;
  font-weight: 100;
  line-height: 0;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: #454545 !important;
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
  height: 50px !important;
  position: absolute;
  top: 35% !important;
}

.App {
  text-align: center;
}

@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 93% !important;
    margin: auto !important;
  }

  .myCarousel {
    margin-top: -9%;
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    height: 269px;
  }

  .carousel .slide img {
    width: 24% !important;
    border-radius: 50%;
    margin-top: 50px;
  }
}

.testimonials h4 {
    font-size: 40px;
    letter-spacing: 3px;
    text-align: center;
    color: #a88659;
    margin-bottom: 25px;
    font-family: 'AGaramondPro';
  }
  
  
.testimonials .control-dots
{
  display: none;
}
.myCarousel .name
{
text-transform: uppercase;
color: #bc9461;
font-size: 16px;
}

.myCarousel .position
{
  text-transform: uppercase;
  color: #bc9461;
  font-size: 12px;
  letter-spacing: 1px;
}

  .carousel .control-next:before, .carousel.carousel-slider .control-next:before
  {
    margin: 0 5px;
    display: inline-block;
    border:none !important;
    content:'\27F6' !important;
    color: #e8b450;
  }
  .carousel .control-prev:before, .carousel.carousel-slider .control-prev:before
  {
    margin: 0 5px;
    display: inline-block;
    border:none !important;
    content:'\27F5' !important;
    color: #e8b450;
  }
  .carousel.carousel-slider .control-arrow:hover 
  {
    background: none !important;
  }
  
  .carousel .control-prev:after, .carousel.carousel-slider .control-prev::after
  {
    margin: 0 5px;
    /* content: '\25CB' !important; */
    color:#e8b45061;
    left: -3px;
    position: absolute;
    font-size: 44px;
    top: -10px;

  }
  .carousel .control-next:after, .carousel.carousel-slider .control-next:after
  {
    margin: 0 5px;
    /* content: '\25CB' !important; */
    color: #e8b45061;
    right: -2px;
    position: absolute;
    font-size: 44px;
    top: -10px;
  }


  /* Residential Testimonials*/

.residential .testimonials
{
  background: #e4dfdc;
}
.residential .testimonials h4
{
color: #33342e;
}
.residential .myCarousel p
{
  color:#2a2c2f;
}
.residential .myCarousel p:before
{
  color: #2a2c2f;
}
.residential .myCarousel p:after
{
  color: #2a2c2f;
}
.residential .myCarousel .name
{
color: #33342e;
}

/*********** CONTACT **************/
.error
{
  color:red;
}
/******* GOOGLE MAP*****/

.iframe-map
{
  padding:40px;
  width:100%;

}
.iframe-map iframe
{
  width:100%;
}

/******** BREADCRUMB ************/

.breadcrumb-item + .breadcrumb-item::before 
{
  content: "\27A4";
}
.breadcrumb
{
  background-color:transparent;
  padding:0px;
  margin: 0px;
}
.breadcrumb-div
{
  padding:10px;
  margin-top: 7rem;
}
.breadcrumb-item.active
{
  text-transform: capitalize;
}

.breadcrumb-item a
{
color:#d2b86b;
}
/********** CLIENTS LOGO ************/
.clients-logo
{
  padding:2% 0;
  text-align: center;
  padding-bottom: 2%;
}

.react-multi-carousel-track li img
{
  width:50%;
}
.react-multi-carousel-item
{
  margin: 0px -30px;
}
.client-image
{
  display: inline-block;
  padding: 0 55px;
  margin: 0;
}
.client-image img
{
  width:75%;
}
.client-image img.keralanews
{
  width: 100%;
}
.vedioView
{
  height: 342px;
}
.newsModal .modal-dialog
{
  max-width: inherit !important;
  width:fit-content !important;
}

/*********** AMENITIES **************/
.amenities
{
  width: 100%;
  height:auto;
  background-image: url(https://sddevelopers.co.in/static/images/amenities_comm.webp);
  background-size: cover;
  background-position: 0px 0px;
  margin-top: -22px;
  background-repeat: no-repeat;
  padding-top: 40px;

}
.commercial .amenities
{
  padding-bottom: 5rem;
}
.amenities h1
{
  color: #eae8e6;
  padding-top: 5px;
  padding-bottom: 45px;
  font-size: 50px;
  letter-spacing: 2px;
  font-family: 'AGaramondPro';
}
.amenities-grid
{
  padding: 0% 10%;
}
.square-service-block{
  position: relative;
  overflow: hidden;
  margin: 3px -12px;
	}
.square-service-block a{
  background-color: #bc9461;
  display: block;
  padding: 73px 20px;
  text-align: center;
  width: 100%;
  height: 321px;
  cursor: inherit;
}
.square-service-block a:hover{
  background-color: rgba(73.7, 58.38.0, 60, 0.8);
}

.ssb-icon {
  color: #fff;
  display: inline-block;
  font-size: 28px;
  margin: 0 0 20px;
}
.ssb-icon img{
  width:100%;
}

h2.ssb-title {
  color: #221d00d6;
  font-size: 16px;
  font-weight: 200;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  line-height: 18px;
  letter-spacing: 1px;
  margin-top: 20px;
  font-family: 'GillSans';
}
.slick-slide img
{
  display:inline-block;
}
.amenities .slick-prev
{
  cursor: pointer;
  position: absolute;
  left: -2%;
  top: 24%;
  width: 20px;
}
.amenities .slick-next
{
  cursor: pointer;
  position: absolute;
  right: -2%;
  top: 24%;
  width: 20px;
}
/************* ENQUIRE ******************/

.enquiry
{
background:#f3ece6;
padding: 0px 10%;
padding-bottom: 135px;
}

.enquire-form h1
{
  padding-top: 60px;
}
.form-row select option
{
  color:#bc9461;
}

.form-row select {
  padding: 20px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('https://sddevelopers.co.in/static/images/arrow-down.png') !important;
  background-repeat: no-repeat !important;
  background-position: 96% 30px !important;

}

.banner-projects .form-row select
{
padding:10px;
}
.dropdown-select
 {
  position: absolute !important;
  content: "|" !important;
  right: 167px !important;
  top: 23px !important;
  border-left: 2px solid #bc94602e !important;
  height: 67px;
 }

.enquiry .submit-row .form-submit
{
  letter-spacing: 4px;
  width: 65%;
  color: #f3ece6;
  background: #33342e;
  height: 55px;
  font-size: 17px;
}

.enquiry .iframe-map
{
  padding:0;
}

.enquiry form
{
  position: relative;
  z-index: 1;
}

.enquiry .submit-row{
  width: 100%;
}

.enquiry .iframe-map img
{
  width: 100%;
}


.residential .enquiry
{
  background: rgb(213,186,150);
  background: linear-gradient(180deg, rgba(213,186,150,1) 50%, rgba(0,0,0,1) 50%, rgba(228,223,220,1) 50%);
  padding-bottom: 0px;
}
.commercial .enquiry
{
  background: rgb(229,214,195);
  background: linear-gradient(180deg, rgba(229,214,195,1) 50%, rgba(0,0,0,1) 50%, rgba(40,40,40,1) 50%);
  padding-bottom: 0px;
}
.commercial .enquire-form h1,.residential .enquire-form h1
{
  padding-top: 0;
}
.enquiry .dropdown-select
{
  right: 87px !important;
  top: 12px !important;
}

/*********CORPORATE OFFICE **************/

.corporate
{
  padding: 5%;
}

.corporate h3
{
  text-transform: capitalize;
  font-size: 40px;
  margin-bottom: 40px;
  letter-spacing: 2px;
  
}

.corporate .text-center h4
{
  color: #bc9461;
  margin-top: 40px;
  letter-spacing: 4px;
  font-size: 23px;
  
}

.corporate .text-center p,.corporate .text-center address
{
  font-size: 21px;
  color: #3e3c3c;
  font-family: 'AGaramondPro';
} 

/********* ABOUT US **********/
.aboutus
{
  padding: 5% 0;
}
.aboutus h1
{
font-size: 50px;
color:#272727;
text-align: center;
margin-bottom: 55px;
margin-top: -30px;
font-family: 'AGaramondPro';
}
.first-about p
{
color:#3d4043;
font-size: 17px;
line-height: 34px;
padding: 0 20%;
width: 100%;
font-family: 'AGaramondPro';
}
.second-about p
{
color:#3d4043;
font-size: 17px;
line-height: 34px;
padding: 0 15%;
width: 100%;
font-family: 'AGaramondPro';
}

.first-about img
{
  width: 75%;
}

.second-about img
{
  width: 100%;
}

.second-about
{
  padding: 4% 10%;
}
/*********** FEATURES ******************/

.features
{
  width: 100%;
  background-image: url('https://sddevelopers.co.in/static/images/features/feature-background.png');
  background-size: cover;
  background-position: 0px;
  background-repeat: no-repeat;
  height:auto;
  margin-top: -70px;
}
.feature-box
{
  padding: 3%;
  text-align: center;
}
.feature-grid
{
  display: inline-block;
  margin: 0 3%;
  position: relative;
}

.feature-grid::after
{
  position: absolute;
  content: "|" !important;
  border-right: 1px solid #dedad78f !important;
  height: 38px;
  right: -56px;
  top: 18%;
}

.feature-grid label
{
  color: #eae9e9fc;
  font-size: 14px;
  text-transform: uppercase;
  display: block;
  margin-top: 20px;
  font-family: 'GillSans';
}

.feature-grid:last-child:after
{
  display: none;
}
.feature-grid:first-child img
{
  height: 93px;
}

/****** GALLERY ********/
.gallery .container-fluid
{
  background-image: url('https://sddevelopers.co.in/static/images/residential_gallery.png');
  background-size: contain;
}

.commercial .gallery .container-fluid
{
  background-image: url('https://sddevelopers.co.in/static/images/gallerybackground.png');
  background-size: contain;
}

.gallery
{
  width: 100%;
  height: auto;
  background-image: url('https://sddevelopers.co.in/static/images/gallerybackground.png');
  /* background-size: contain; */
  background-position:top;
  background-repeat: no-repeat;
}
.gallery h3
{
  text-transform: uppercase;
  color: #a88659;
  text-align: center;
  letter-spacing: 4px;
  font-size: 21px;
  padding-top: 5%;
}

.gallery h1
{
  color: #ffffff;
  text-align: center;
  letter-spacing: 4px;
  font-size: 52px;
  margin-bottom: 30px;
  font-family: 'AGaramondPro';
}

.filter-nav {
  text-align: center;
}
.gallery .filter-nav button
{
height:60px;
margin:13px;
letter-spacing: 6px;
width: 25%;
color: #282828;
}

.gallery .filter-nav button.active
{
  background: #ffffff !important;
}

.gallery .filter-nav button,.gallery .filter-nav button:visited,.gallery .filter-nav button:active,.gallery .filter-nav button:focus{
  outline:none !important;
  box-shadow:none !important;
}

.filter-basic
{
  margin-top: 1%;
}
.galbox
{
margin: 0px !important;
padding: 0px !important;
}
.galbox .img-fluid
{
  width:100% !important;
}
.gallery .galgrid
{
  margin:0px !important;
}
.gallery .animated.faster {
	-webkit-animation-duration: .5s;
	animation-duration: .5s
}
.gallery .zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
} 

.gallery .animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both
}


.gallery .animated.infinite {
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite
}


.gallery .animated.fast {
	-webkit-animation-duration: .8s;
	animation-duration: .8s
}

.gallery .animated.faster {
	-webkit-animation-duration: .5s;
	animation-duration: .5s
}


@media (prefers-reduced-motion) {
	.gallery .animated {
		-webkit-animation: unset!important;
		animation: unset!important;
		-webkit-transition: none!important;
		-o-transition: none!important;
		transition: none!important
	}
}

.zoomIn {
	-webkit-animation-name: zoomIn;
	animation-name: zoomIn
}


@-webkit-keyframes zoomIn {
	from {
		opacity: 0;
		-webkit-transform: scale3d(.3, .3, .3);
		transform: scale3d(.3, .3, .3)
	}
	50% {
		opacity: 1
	}
}

@keyframes zoomIn {
	from {
		opacity: 0;
		-webkit-transform: scale3d(.3, .3, .3);
		transform: scale3d(.3, .3, .3)
	}
	50% {
		opacity: 1
	}
}
.gallery .btn-primary.active
{
  border-color:#545355 !important;
}



/******** RESIDENTIAL AMENITIES************/

.residential .amenities h1
{
  padding-top:20px;
}
.residential .amenities-grid
{
  margin-top: -26px;
}
.residential .amenities
{
  width: 100%;
  height: auto;
  background-image: url(https://sddevelopers.co.in/static/images/amenities_residential.webp);
  background-size: cover;
  background-position: 0px 0px;
  background-repeat: no-repeat;
}

.hexagon {
  width: 159px;
  height: 88px;
  background: #bc9461;
  position: relative;
  margin:5rem auto;
  text-align: center;

}

.hexagon:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: -50px;
  left: 0;
  border-left: 79px solid transparent;
  border-right: 80px solid transparent;
  border-bottom: 50px solid #bc9461;
}

.hexagon:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  bottom: -50px;
  left: 0;
  border-left: 79px solid transparent;
  border-right: 80px solid transparent;
  border-top: 50px solid #bc9461;
}


.residential .amenities-grid h2
{
color: #dedddd;
font-size: 15px;
font-weight: 200;
text-transform: uppercase;
line-height: 24px;
margin-bottom: 30%;
text-align: center;
font-family: 'GillSans';
}
.residential .amenities .myCarousel
{
  height:auto;
}

/******* RESIDENTIAL GALLERY **********/

.residential .gallery {
  width: 100%;
  height: auto;
  background-image: url(https://sddevelopers.co.in/static/images/residential_gallery.png);
  /* background-size: contain; */
  background-position: top center;
  background-repeat: no-repeat;
}

.residential .gallery h1
{
  color:#272727;
}
.residential .gallery .btn-primary
{
  color: #ffffff !important;
}
.residential .gallery .btn-primary.active
{
  border-color:#545355 !important;
  color: #000000 !important;
}
.residential .gallery h3 
{
  margin-bottom: 20px;
}

.gallery .row.galgrid {
   border-bottom: none!important; 
   text-align: center;
   display: list-item; 
   float: none;
   }
.gallery .galbox
{
margin: 0!important;
padding: 0!important;
position: relative;
display: inline-block;
}
/************** PROJECT PAGE FORM *****************/

.project-page .form-row input, .project-page .form-row textarea, .project-page .form-row select
{
  background: #e5d6c3;
  border-radius: 0;
}

.project-page .submit-row
{
  margin-top:20px;
  position: inherit;
  text-align: left !important;
}

.project-page .form-row
{
  max-width: 100%;
}
.project-page .submit-row .form-submit
{
  width: 100%;
}

.project-form-background
{
  background-color: #ffffff;
  padding: 4%;
}

.project-page .dropdown-select
{
  position: relative !important;
  right: 75px !important;
  top: 7px !important;
  height: 65px;
}
 .project-page .form-row select
{
  background-image: url(https://sddevelopers.co.in/static/images/down-arrow.png) !important;
  margin-top: -58px;
}
.project-page .form-row input,.project-page .form-row textarea,.project-page .form-row select
{
  border:none;
}

.project-page .form-row select {
  background-position: 95% 32px !important;
}
.residential .iframe-map img,.commercial .iframe-map img
{
  width:100%;
  margin-top: 3rem;
}
/****************** PROJECT DETAILS ************************/
.project-info
{
  padding: 3% 10%;
}
.project-info h2
{
  color: #bc9461;
  text-transform: uppercase;
  font-family: 'AGaramondPro';
  font-size: 25px;
  letter-spacing: 6px;
  margin-bottom: 10px;
}
.project-info h3
{
  color:#454545;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 20px;
  font-family: 'AGaramondPro';
}
.project-info h1
{
  color:#33342e;
  font-size: 51px;
  margin-bottom: 60px;
  letter-spacing: 1px;
  font-family: 'AGaramondPro';
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; 
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width:100%;
}

.project-info p
{
    color: #3d4043;
    font-size: 18px;
    line-height: 34px;
    width: 100%;
    font-family: 'AGaramondPro';
}
.project-details
{
background-color: #d5ba96;
}
.react-tabs__tab
{
  font-size: 13px;
  background-color: #33342e;
  color: #e5d6c3;
  padding:18px 5px !important;
  margin: 1px 1px;
  vertical-align: middle;
  text-align: center;
  height: 52px;
  line-height: 14px;
  text-transform: uppercase;
}
.react-tabs__tab--selected {
  background:transparent !important;
  border-color: none !important;
  color: #33342e !important;
  border-radius: unset !important;
  border: 1px solid transparent !important;
}
.react-tabs__tab-list
{
  border-bottom: none !important;
  text-align: center;
  display: list-item;
  float: none;
}

.project-details .list-group-item:first-child
{
border-top-left-radius:unset !important;
border-top-right-radius:unset !important;
background-color: #33342e !important;
color:#f7f2ef;
text-transform: uppercase;
font-size: 16px;
letter-spacing: 0px;
}
.project-details .project-content
{
  padding: 4% 0%;
  padding-bottom: 0;
}
.project-details .list-group-item
{
  padding:5% !important;
  font-size: 14px;
  text-transform: uppercase;
  color: #4b4a42;
}

.project-details .list-group-item:nth-child(even)
{
  background-color:#f7f2ef;
}

.project-details .list-group-item:nth-child(odd)
{
  background-color:#e5d6c3;
}
.project-button-one
{
  letter-spacing: 2px;
  width: 25%;
  color: #eae5e2;
  background: #33342e;
  height: 55px;
  font-size: 13px;
  text-transform: uppercase;
  border: none;
  margin: 0 10px 0 0;
}
.project-button-two
{
  letter-spacing: 2px;
  width: 31%;
  color: #eae5e2;
  background: #33342e;
  height: 55px;
  font-size: 13px;
  text-transform: uppercase;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url(https://sddevelopers.co.in/static/images/project_download_button.png) !important;
  background-repeat: no-repeat !important;
  background-position: 94% 5px !important;
  text-align: left;
  padding-left: 5%;
  
}
.project-button
{
  padding: 0;
  margin-top: 3rem;
}

/**commercial project details**/

.commercial .project-info
{
background-color: #282828;
padding-bottom: 5%;
}
.commercial .project-info h1 {
  color: #ffffff;
}
.commercial .project-info h3 {
  color: #ffffff;
  line-height: 30px;
  margin-bottom: 30px;
}
.commercial .project-info p {
  color: #ffffff;
  width:90%;
}

.commercial .react-tabs__tab
{
  padding: 16px 78px !important;
  background-color: #282828;
  border: 2px solid #d5ba96 !important;
  line-height: 15px;
  height: 56px;
  margin:-2px;
}
.commercial .project-details {
  background-color: #e5d6c3;
}

.commercial .project-details .list-group-item
{
  border:none;
}
.commercial .project-details .list-group-item:nth-child(even)
{
  background-color:#f7f2ef;
}

.commercial .project-details .list-group-item:nth-child(odd)
{
  background-color:#ffffff;
}
.project-image
{
  width:100%;
}

/**MODAL**/
.modal-dialog .form-submit
{
cursor: pointer;
letter-spacing: 4px;
width: 100%;
color: #f3ece6;
background: #33342e;
height: 55px;
font-size: 17px;
}
/**************** LANDMARKS ********************/

.landmark
{
  width: 100%;
  height: auto;
  background-image: url('https://sddevelopers.co.in/static/images/landmark_residential_background.png');
  background-size: cover;
  background-position: center center;
  margin-top: 0;
  background-repeat: no-repeat;
  min-height: 1092px;
}

.landmark .landmark-h1 h1
{
  color: #3e403b;
  text-align: center;
  letter-spacing: 1px;
  font-size: 50px;
  margin-top: 5%;
  text-transform: capitalize;
  padding: 7px 15px;
  font-family: 'AGaramondPro';

}
.landmark-h1 {
  background-color: #d5ba96;
}
.landmark .card-header button
{
  color: #0d1014;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 3px;
  font-size: 13px;
  padding-top: 3px;
}

.landmark .accordion
{
  margin-top: -8px;
}
.landmark .card-header button,.landmark .card-header button:hover,.landmark .card-header button:active,.landmark .card-header button:visited,.landmark .card-header button:focus
{
  outline: none !important;
  box-shadow: none !important;
}
.landmark .accordion > .card
{
  padding:10px;
  border: none;
  background-color:#f7f2ef;
}
.landmark .card-header
{
  background-color:#f7f2ef;
  border-bottom: 1px solid #38353954; 
}
.landmark .accordion .card:last-child .card-header
{
  border-bottom: none;
}
.landmark .accordion > .card > .card-header
{
  padding: 15px 10px;
  cursor: pointer;
}

.landmark .card-header label {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  padding: 0;
  position: relative;
  text-decoration: none;
  float: right;
  padding-top: 15px;
  padding-right: 10px;
}


.landmark .card-header label:before, .landmark .card-header label:after {
  border-right: 1px solid;
  content: '';
  display: block;
  height: 8px;
  margin-top: -7px;
  position: absolute;
  -moz-transform: rotate(146deg);
  -o-transform: rotate(146deg);
  -webkit-transform: rotate(146deg);  
  transform: rotate(146deg);
  width: 0;
  color: #bc9461;
}

.landmark .card-header label:after {
  margin-top: -1px;
  -moz-transform: rotate(33deg);
  -o-transform: rotate(33deg);
  -webkit-transform: rotate(33deg);
  transform: rotate(33deg);
}


.landmark .card-header label.label-down:after
{
    margin-top: -7px;
    -moz-transform: rotate(-52deg);
    -o-transform: rotate(-52deg);
    -webkit-transform: rotate(-52deg);
    transform: rotate(-52deg);
    margin-left: -6px;
    color: #bc9461;
}

.landmark .card-header label.label-down:before
{
  transform: rotate(228deg);
  -moz-transform: rotate(228deg);
  -o-transform: rotate(228deg);
  -webkit-transform: rotate(228deg); 
  margin-top: -7px;
  position: absolute;
  width: 0;
  border-right: 1px solid;
  content: '';
  display: block;
  height: 8px;
}

.landmark .card-body ul
{
  list-style-type: none;
  margin:-8px;
}

.landmark .card-body ul li
{
  padding: 15px 0px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
}
.landmark-img
{
  padding: 0;
  margin-top: -8px;
}

.accordian-col
{
  padding-right: 0;
  padding-left: 0;
  margin: 0;
}
.landmark-img img
{
width: 100%;
}
.landmark-head
{
  padding: 0;
}

.landmark .accordion > .card:first-child
{
  padding-top: 10px;
}
.landmark .card-body ul li.active
{
  color: #bc9461;
}

.commercial .landmark
{
  background-image: url(https://sddevelopers.co.in/static/images/landmark_background.png);
}
.commercial .landmark .landmark-h1 h1
{
  background-color: #bc9461;
  color: #f7f2ef;
}

.commercial .landmark .card-header label:before,.commercial .landmark .card-header label:after
{
  color:#bc9461;
}


/***** MODAL FORM ******/

.modal .form-row input[type="text"],.modal .form-row input[type="email"]
{
  background: #e5d6c3;
}
.modal .form-row input
{
padding:15px;
}
.modal fieldset
{
  padding-bottom: 0 !important;
}

.whatsapp{
bottom: 10px;
    left: 15px;
    opacity: 1;
    transition: opacity 0.5s ease 0s;
    box-sizing: border-box;
    direction: ltr;
    position: fixed !important;
    z-index: 16000160 !important;
}

/******** Blogs *********/
.blog-article
{
  margin-top: 5%;
  margin-bottom: 2%;
}
.blog-details .galbox .href{
border: 1px solid rgba(83,82,82,.1411764705882353);
/* border-top: 5px solid #53525224; */
margin: 45px 30px 0 30px;

padding: 20px;
margin-top: 13px;
}
.blog-details
{
  text-align: center;
}
.blog-details .react-tabs__tab-list
{
display: inline-flex;
}

.blog-details .react-tabs__tab 
{
  list-style: none;
}
.blog-details .react-tabs__tab
{
  background-color: #ffffff;
}
.blog-details .react-tabs .react-tabs__tab--selected
{
  color:red !important;
}

.blog-details .react-tabs__tab{
  color:#5e5959f2 !important;
  cursor: pointer;
  margin: 0px 20px;
  font-size: 17px;
  text-transform: capitalize;
  font-weight: bold;
}


.blog-details .galbox .item-content
{
  padding: 10px;
  height: 375px;
}
.blog-details h2{
      padding: 10px 0px;
      text-align: left;
      font-size: 20px;
      font-weight: bold;
      color: #2c2727fc;
}
.blog-details p{
  text-align: left;
  font-size: 15px;
  color: #565353fc;
  margin-bottom: -5px;
}
.blog-details font
{
  font-size: 45px;
}
.blog-details a{
  float: right;
  text-transform: uppercase;
  color: #df0606e8;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 15px;
  margin-top: -52px;
}
.blog-details .dropdown-select
{
position: absolute !important;
content: "|" !important;
right: 95px ​!important;
top: 557px !important;
border-left:1px solid rgb(0 0 0 / 20%) !important;
height: 38px;
}
.blog-details .href
{
margin-bottom: 10%;
}
.blog-details .container.project-content {
  padding: 0px;
}
.blog-details img{
  width: 100%;
  max-height: 350px;
  height:250px;
}
.blog-details hr
{
  border-top:1px solid rgb(0 0 0 / 20%) !important;
  margin-bottom: 35px;
}

.blog-details .dropdown-select
{
  right:95px !important;
}


/********* Blog Comments ************/

.blog-comments{
  background-color: #74674154;
  padding: 70px;
}

.blog-comment-title{
  text-align: center;
}
.blogsoacil
{
display: inline-block;
margin-top: 10px;
}
.blogsoacil a img
{
  width:20px !important;
}
.blog-comments-inner
{
      padding: 35px;
      background-color: #ffffff;
      margin: 5% 15% 3% 15%;
}
.blog-comments-inner h3{
  text-transform: uppercase;
}
.blog-comments-inner h1{
font-size: 27px;
text-transform: capitalize;
font-weight: bold;
}
.blog-comments-inner h3{
font-size: 15px;
}
.blog-comments-inner span{
  font-size: 15px;
  color:#898686;
}
.blog-comments-inner font{
font-size: 15px;
}
.blog-comments-inner .blogsoacil a
{
    border: 1px solid #d2d2d2;
    padding: 5px;
    border-radius: 100%;
    margin: 5px;
}

.blog-comments-inner hr {
  border-top: 2px solid rgb(0 0 0 / 9%) !important;
}

.blog-comments-replay
{
  padding: 35px;
  background-color:#d2d2d2;
  margin: -4% 15% 1% 15%;
 border-top:2px solid rgb(0 0 0 / 9%) !important;
}
.blog-comments-replay h1{
  font-size: 24px;
    text-transform: capitalize;
    font-weight: bold;
}
.blog-comments-replay hr{
  border-top: 1px solid rgb(0 0 0 / 12%) !important
}


.blog-comments-form
{
  padding: 35px;
  background-color:#ffffff;
  margin: -4% 15% 1% 15%;
  border-top:2px solid rgb(0 0 0 / 42%) !important;
}

.blog-comments-form h1{
  font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
}
.blog-comments-form hr {
  border-top: 1px solid rgb(0 0 0 / 9%) !important;
}
.blog-comments-form input{
  display: block;
    border: none;
    border-bottom:1px solid #d2d2d2;
    outline: none;
    width: 100%;
    border-radius: 4px;
    color: #32325d;
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    align-self: center;
    padding: 20px;
    flex: 70% 1;
    width: 100%;
    margin-bottom:10px;
}

.blog-comments-form textarea{
  display: block;
    border: none;
    border-bottom:1px solid #d2d2d2;
    outline: none;
    width: 100%;
    border-radius: 4px;
    color: #32325d;
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    align-self: center;
    padding: 20px;
    flex: 70% 1;
    width: 100%;
}
.replay-content font
{
font-size: 13px;
margin-left:7px;
}
.replay-icon
{
  float: right;
  cursor:pointer;
}

.blog-comments-form .form-submit
{
  letter-spacing: 4px;
  width: 100%;
  color: #f3ece6;
  background: #33342e;
  height: 55px!important;
  font-size: 12px;
  margin-top: 20px;
}
.rc
{
  margin: 3% 20%;
  background: white;
  padding: 4%;
  padding-bottom: 0px;
}

#blogname:focus {
  border:1px solid red;
}

.image-logo-mobile
{
  pointer-events: none;
}
.image-logo-mobile img
{
  pointer-events:visible;
}

.blog-comments-inner img 
{
  width:100% !important;
}

.blog-comments-form .form-row
{
max-width: 100% !important;
}
.blog-details .react-tabs__tab:focus
{
  box-shadow:none !important;
  border-color:transparent !important;
}

pre{display: none !important;}
.react-tabs__tab:focus{box-shadow: none !important;}
.react-tabs__tab:focus:after{background: none !important;}